import { NovelCustomerPassSubReducer } from "@customer-pass/redux/customerPassTypedRedux";
import { IOrgResolvedFromHandle } from "@novel/shared/interfaces/Organization";
import { useNovelCustomerPassSelector } from "@customer-pass/redux/reduxHooks";

export interface IOrgDataReducerState {
    isLoadingOrganization: boolean;
    resolvedOrg: IOrgResolvedFromHandle | null;
    errorLoading: string | null;
}

const initialState: IOrgDataReducerState = {
    isLoadingOrganization: true,
    errorLoading: null,
    resolvedOrg: null,
};

export const orgDataReducer: NovelCustomerPassSubReducer<IOrgDataReducerState> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOADING_RESOLVED_ORG": {
            return {
                ...state,
                isLoadingOrganization: true,
            };
        }

        case "ERROR_LOADING_RESOLVED_ORG": {
            return {
                ...state,
                isLoadingOrganization: false,
                errorLoading: action.payload.errorMessage,
            };
        }

        case "LOADED_RESOLVED_ORG": {
            return {
                ...state,
                isLoadingOrganization: false,
                resolvedOrg: action.payload.resolvedOrg,
            };
        }

        default: {
            return state;
        }
    }
};

export function useIsDemoOrg(): boolean {
    return useNovelCustomerPassSelector(
        (state) => !!state.orgData.resolvedOrg && !state.orgData.resolvedOrg.shopData,
    );
}
