import {
    NovelCustomerPassAction,
    NovelCustomerPassDispatch,
    NovelCustomerPassThunkAction,
} from "@customer-pass/redux/customerPassTypedRedux";
import { asyncDispatch } from "@customer-pass/redux/utils/asyncDispatch";
import { typedFrontendPassApi } from "@customer-pass/utils/typedFrontendPassApi";
import { IResolvedFromHandleRes } from "@novel/shared/interfaces/Organization";
import { TypedCustomerApi } from "@novel/shared/typedCustomerApi/TypedCustomerRoutes";
import { iosBrowserInstallInstancePropertyName } from "@novel/shared/utils/appConstants";
import type { FiatCurrencyCode } from "@novel/shared/utils/formatMoney";
import { parseCookiesToObj } from "@novel/shared/utils/cookieUtils";
import { IOrgCustomerRelationshipWithRewardsStatus } from "@novel/shared/interfaces/OrgCustomerRelationship";

export const loadSession =
    (
        customerApi: TypedCustomerApi = typedFrontendPassApi,
        queryObj: {
            referralCode?: string;
            includeSessionToken?: "true";
            sourceTag?: string;
        } = {},
    ): NovelCustomerPassThunkAction<
        | "LOADING_RESOLVED_CUSTOMER"
        | "LOADING_RESOLVED_ORG"
        | "ERROR_LOADING_RESOLVED_ORG"
        | "ERROR_LOADING_RESOLVED_CUSTOMER"
        | "SET_WALLET_PASS_INSTALL_FLOW"
        | "LOADED_RESOLVED_ORG"
        | "LOADED_RESOLVED_CUSTOMER"
    > =>
    async (dispatch) =>
        asyncDispatch(
            customerApi.getReq("/api/customer/auth/pass/session", {
                query: queryObj,
            }),
            () => {
                dispatch({
                    type: "LOADING_RESOLVED_ORG",
                    payload: undefined,
                });

                return dispatch({
                    type: "LOADING_RESOLVED_CUSTOMER",
                    payload: undefined,
                });
            },
            (failure) => {
                dispatch({
                    type: "ERROR_LOADING_RESOLVED_ORG",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                });

                return dispatch({
                    type: "ERROR_LOADING_RESOLVED_CUSTOMER",
                    payload: {
                        errorMessage: failure.error.message,
                    },
                });
            },
            (resolvedHandleData) =>
                dispatchResolvedHandleData(
                    resolvedHandleData.resolvedData!,
                    dispatch,
                    resolvedHandleData.resolvedReferrer,
                ),
        );

export function dispatchResolvedHandleData(
    resolvedHandleData: IResolvedFromHandleRes,
    dispatch: NovelCustomerPassDispatch<
        "LOADED_RESOLVED_ORG" | "SET_WALLET_PASS_INSTALL_FLOW" | "LOADED_RESOLVED_CUSTOMER"
    >,
    resolvedReferrer?: IOrgCustomerRelationshipWithRewardsStatus,
): NovelCustomerPassAction<
    "LOADED_RESOLVED_ORG" | "SET_WALLET_PASS_INSTALL_FLOW" | "LOADED_RESOLVED_CUSTOMER"
> {
    const {
        resolvedFromReq: {
            walletPassInstallFlow,
            isAppleDevice,
            isChromeBrowser,
            computedCountryCode,
            computedCurrencyCode,
        },
        resolvedOrg,
        resolvedCustomer,
    } = resolvedHandleData;

    dispatch({
        type: "LOADED_RESOLVED_ORG",
        payload: {
            resolvedOrg,
        },
    });
    dispatch({
        type: "SET_WALLET_PASS_INSTALL_FLOW",
        payload: {
            walletPassInstallFlow,
            isAppleDevice,
            isChromeBrowser,
            computedCountryCode,
            computedCurrencyCode: computedCurrencyCode as FiatCurrencyCode,
            ...(walletPassInstallFlow === "android-phone" && resolvedCustomer?.passInstallLink
                ? {
                      passDownloadUrl: resolvedCustomer?.passInstallLink,
                  }
                : {}),
            ...(resolvedCustomer?.currentWalletPass
                ? { currentWalletPass: resolvedCustomer.currentWalletPass }
                : {}),
            resolvedReferrer,
        },
    });

    if (resolvedCustomer) {
        return dispatch({
            type: "LOADED_RESOLVED_CUSTOMER",
            payload: {
                resolvedCustomer,
            },
        });
    }

    return dispatch({
        type: "LOADED_RESOLVED_CUSTOMER",
        payload: {
            resolvedCustomer: null,
        },
    });
}

export const loadCurrentWalletPass =
    (
        customerApi: TypedCustomerApi = typedFrontendPassApi,
    ): NovelCustomerPassThunkAction<
        | "LOADING_CURRENT_WALLET_PASS"
        | "ERROR_LOADING_CURRENT_WALLET_PASS"
        | "LOADED_CURRENT_WALLET_PASS"
    > =>
    async (dispatch, getState) =>
        asyncDispatch(
            (async () => {
                const cookiesObj = parseCookiesToObj(
                    typeof document === "undefined" ? "" : document.cookie,
                );
                const currentSerialNumber = cookiesObj[iosBrowserInstallInstancePropertyName];
                if (!currentSerialNumber) {
                    return {
                        type: "success",
                        body: {},
                    };
                }

                const state = getState();
                if (state.passUi.walletPassInstallFlow === "android-phone") {
                    const currentDeviceId = state.auth.resolvedCustomer?.currentDevice?.id;
                    if (!currentDeviceId) {
                        return {
                            type: "success",
                            body: {},
                        };
                    }

                    return await customerApi.getReq(
                        "/api/customer/auth/pass/session/current-wallet-pass",
                        {
                            query: {
                                currentDeviceId,
                            },
                        },
                    );
                }

                return await customerApi.getReq(
                    "/api/customer/auth/pass/session/current-wallet-pass",
                    {
                        query: {
                            currentSerialNumber,
                        },
                    },
                );
            })(),
            () =>
                dispatch({
                    type: "LOADING_CURRENT_WALLET_PASS",
                    payload: undefined,
                }),
            (failure) =>
                dispatch({
                    type: "ERROR_LOADING_CURRENT_WALLET_PASS",
                    payload: {
                        user: null,
                        errorMessage: failure.error.message,
                    },
                }),
            (response) =>
                dispatch({
                    type: "LOADED_CURRENT_WALLET_PASS",
                    payload: {
                        currentWalletPass: response.currentWalletPass,
                    },
                }),
        );
