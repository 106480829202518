export const storefrontGateModalRootElementId = "novel-gate-modal-root-element";
export const storefrontAuthModalRootElementId = "novel-auth-modal-root-element";
export const toastsRootElementId = "novel-toast-root-element";

export const initStorefrontGateRootRenderElement = genCreateRootRenderElement(
    storefrontGateModalRootElementId,
);
export const initStorefrontAuthModalRootRenderElement = genCreateRootRenderElement(
    storefrontAuthModalRootElementId,
);
export const initToastsRootRenderElement = genCreateRootRenderElement(
    toastsRootElementId,
    999999999,
);

function genCreateRootRenderElement(rootElementId: string, zIndex?: number): () => HTMLDivElement {
    let cachedElement: HTMLDivElement | null = null;
    return function getRootRenderElement(): HTMLDivElement {
        if (!cachedElement) {
            const rootElement = window.document.createElement("div");
            rootElement.id = rootElementId;
            rootElement.className = "novel-root-element rs-theme-light";
            rootElement.style.position = "absolute";
            rootElement.style.top = "100%";
            rootElement.style.zIndex = zIndex ? `${zIndex}` : "9999998"; // one less than the z-index of the Torus

            // insert child at the beginning of the body
            // otherwise we were having odd scrolling behavior
            const scrollX = typeof window !== "undefined" ? window.scrollX : undefined;
            const scrollY = typeof window !== "undefined" ? window.scrollY : undefined;

            // adding link for Novel fonts
            const link = document.createElement("link");
            link.href =
                "https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap";
            link.rel = "stylesheet";
            const head = document.getElementsByTagName("head");
            head[0].appendChild(link);

            document.body.appendChild(rootElement);

            // ensure scroll position is the same after insert of element
            if (
                typeof window !== "undefined" &&
                typeof scrollX !== "undefined" &&
                typeof scrollY !== "undefined" &&
                (window.scrollX !== scrollX || window.scrollY !== scrollY)
            ) {
                window.scrollTo(scrollX, scrollY);
            }

            cachedElement = rootElement;
        } else if (!cachedElement.isConnected) {
            document.body.appendChild(cachedElement);
        }
        return cachedElement;
    };
}
