"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromShopifyIframePropertyName = exports.orgIdPropertyName = exports.myShopifyDomainUrlPropertyName = exports.DEVELOPMENT_LOGGED_OUT_CUSTOMER_ID_SIGNAL = exports.shopifyShopGidPrefix = exports.shopifyCustomerGidPrefix = exports.shopifyProductVariantGidPrefix = exports.shopifyOrderGidPrefix = exports.shopifySubscriptionContractGidPrefix = exports.shopifyDiscountNodeGidPrefix = exports.shopifyProductGidPrefix = exports.provenancePagePrefix = exports.jobsUiPrefix = exports.publicApiPrefix = exports.customerApiPrefix = exports.customerPassPrefix = exports.customerGalleryPrefix = exports.vendorApiPrefix = exports.vendorStandalonePrefix = exports.vendorPortalPrefix = exports.docsPortalPrefix = exports.IPFS_GATEWAY_PREFIX = exports.IPFS_PREFIX = exports.EXCLUDED_ADDRESSES = exports.BURN_ADDRESS = exports.accepted3dFileTypes = exports.acceptedVideoFileTypes = exports.acceptedSoundFileTypes = exports.acceptedShopifyImageFileTypes = exports.acceptedPassImageFileTypes = exports.acceptedImageFileTypes = exports.DEFINED_STARTING_HOST_SUBDOMAINS = exports.DEFINED_STARTING_HOST_PAGE_SUBDOMAINS = exports.PASSWORDLESS_TOKEN_EXPIRATION_TIMEOUT = exports.PASSWORDLESS_SIX_DIGIT_CODE_EXPIRATION_TIMEOUT = exports.DEV_GENERATOR_LIMIT = exports.MAX_VIDEO_UPLOAD_SIZE = exports.MAX_TOKEN_IMAGE_UPLOAD_SIZE = exports.MAX_IMAGE_UPLOAD_SIZE = exports.MAX_COLLECTION_IMAGE_UPLOAD_SIZE = exports.ONE_MEGABYTE = exports.CONVERSION_FROM_DECIMAL_TO_BASIS_POINTS = exports.MAX_COLLECTION_ROYALTY_FEE_IN_PERCENTAGE_POINTS = exports.MAX_COLLECTION_ROYALTY_FEE_IN_BASIS_POINTS = exports.NOVEL_ROYALTY_FEE_IN_BASIS_POINTS = exports.DISABLE_AUTO_LIQUID_EDIT_COMMENT = exports.DISABLE_AUTO_LIQUID_EDIT_TEXT = exports.END_NOVEL_DELIMITER = exports.NOVEL_UNINSTALLED_STATE = exports.BEGIN_NOVEL_DELIMITER = void 0;
exports.GOOGLE_MAPS_API_KEY = exports.DEFAULT_GOOGLE_OVERLAY = exports.DEFAULT_PASS_GOOGLE_STRIP_IMAGE = exports.DEFAULT_APPLE_INSTALL_OVERLAY = exports.DEFAULT_APPLE_OVERLAY = exports.DEFAULT_PASS_STRIP_IMAGE = exports.DEFAULT_PASS_GOOGLE_LOGO_IMAGE = exports.DEFAULT_PASS_APPLE_LOGO_IMAGE = exports.DEFAULT_PASS_EMAIL_IMAGE = exports.DEFAULT_PASS_ICON_IMAGE = exports.ICON_IMAGE_HEIGHT = exports.ICON_IMAGE_WIDTH = exports.GOOGLE_STRIP_IMAGE_HEIGHT = exports.GOOGLE_STRIP_IMAGE_WIDTH = exports.GOOGLE_LOGO_IMAGE_HEIGHT = exports.GOOGLE_LOGO_IMAGE_WIDTH = exports.APPLE_LOGO_IMAGE_X3_HEIGHT = exports.APPLE_LOGO_IMAGE_X3_WIDTH = exports.APPLE_LOGO_IMAGE_X2_HEIGHT = exports.APPLE_LOGO_IMAGE_X2_WIDTH = exports.APPLE_LOGO_IMAGE_HEIGHT = exports.APPLE_LOGO_IMAGE_WIDTH = exports.STRIP_IMAGE_X3_HEIGHT = exports.STRIP_IMAGE_X3_WIDTH = exports.STRIP_IMAGE_X2_HEIGHT = exports.STRIP_IMAGE_X2_WIDTH = exports.STRIP_IMAGE_HEIGHT = exports.STRIP_IMAGE_WIDTH = exports.GOOGLE_GENERIC_PASS_CLASS_NAME = exports.ACTIVE_SUBSCRIBER_TAG = exports.BANNED_CONTRACTS = exports.DEFAULT_EMAIL_TEMPLATE_BACKGROUND = exports.SINGLE_ATTRIBUTE_LAYER = exports.NONE_TRAIT_ID = exports.getApiKeyPropertyName = exports.INFLUENCE_IO_HEADERS = exports.ALCHEMY_QUERY_PARAMS = exports.ALCHEMY_HEADERS = exports.CUSTOMER_HEADERS = exports.VENDOR_API_HEADERS = exports.VENDOR_HEADERS = exports.PUBLIC_HEADERS = exports.SHOPIFY_HEADERS = exports.iosBrowserInstallInstancePropertyName = exports.webPushSubscriptionEndpointPropertyName = exports.customerCsrfPropertyName = exports.customerJwtPropertyName = exports.vendorJwtPropertyName = exports.NOVEL_SOURCE_TAG_PARAM = exports.NOVEL_PASS_USER_EMAIL_PARAM = void 0;
exports.orderStatusUnproxiedScriptTag = exports.orderStatusProxiedScriptTag = exports.storefrontUnproxiedScriptTag = exports.storefrontProxiedScriptTag = exports.DEFAULT_WELCOME_REFFERALS_TEXT = exports.DEFAULT_WELCOME_PASS_TEXT = exports.REFERREE_BALANCE_TEXT_EDITOR_MASK = exports.SHOP_NAME_EDITOR_MASK = exports.REFERRER_TEXT_EDITOR_MASK = exports.PUSH_BODY_TEXT_VARIABLES = exports.TEXT_VARIABLE_DELIMITER = void 0;
// ensure relative paths
var appStorefrontConstants_1 = require("./appStorefrontConstants");
var env_1 = require("./env");
var appDomainConstants_1 = require("./appDomainConstants");
exports.BEGIN_NOVEL_DELIMITER = "<!-- BEGIN NOVEL SNIPPET -->";
exports.NOVEL_UNINSTALLED_STATE = "<!-- NOVEL script will not rendered, the associated app was uninstalled -->";
exports.END_NOVEL_DELIMITER = "<!-- END NOVEL SNIPPET -->";
exports.DISABLE_AUTO_LIQUID_EDIT_TEXT = "NOVEL_CUSTOMIZED_NO_AUTO_EDIT";
exports.DISABLE_AUTO_LIQUID_EDIT_COMMENT = "<!-- ".concat(exports.DISABLE_AUTO_LIQUID_EDIT_TEXT, " -->");
var OPENSEA_MAX_ROYALTY_FEE_IN_BASIS_POINTS = 1000;
exports.NOVEL_ROYALTY_FEE_IN_BASIS_POINTS = 100;
exports.MAX_COLLECTION_ROYALTY_FEE_IN_BASIS_POINTS = OPENSEA_MAX_ROYALTY_FEE_IN_BASIS_POINTS - exports.NOVEL_ROYALTY_FEE_IN_BASIS_POINTS;
exports.MAX_COLLECTION_ROYALTY_FEE_IN_PERCENTAGE_POINTS = exports.MAX_COLLECTION_ROYALTY_FEE_IN_BASIS_POINTS / 100;
exports.CONVERSION_FROM_DECIMAL_TO_BASIS_POINTS = 10000;
exports.ONE_MEGABYTE = 1048576;
exports.MAX_COLLECTION_IMAGE_UPLOAD_SIZE = exports.ONE_MEGABYTE * 5; // 5MB, ensures image works in email
exports.MAX_IMAGE_UPLOAD_SIZE = exports.ONE_MEGABYTE * 25; // 25MB
exports.MAX_TOKEN_IMAGE_UPLOAD_SIZE = exports.ONE_MEGABYTE * 100; // 100MB;
exports.MAX_VIDEO_UPLOAD_SIZE = exports.ONE_MEGABYTE * 100; // 100MB
exports.DEV_GENERATOR_LIMIT = 500;
exports.PASSWORDLESS_SIX_DIGIT_CODE_EXPIRATION_TIMEOUT = 24 * 60 * 60 * 1000; // 1 day
exports.PASSWORDLESS_TOKEN_EXPIRATION_TIMEOUT = 30 * 24 * 60 * 60 * 1000; // 30 days
exports.DEFINED_STARTING_HOST_PAGE_SUBDOMAINS = env_1.AppEnv === env_1.AppEnvEnum.development ? ["novel-dev"] : ["app"];
exports.DEFINED_STARTING_HOST_SUBDOMAINS = env_1.AppEnv === env_1.AppEnvEnum.development ? ["novel-dev"] : ["app", "api"];
exports.acceptedImageFileTypes = ["image/gif", "image/jpeg", "image/png", "image/svg+xml"];
exports.acceptedPassImageFileTypes = ["image/png"];
exports.acceptedShopifyImageFileTypes = ["image/gif", "image/jpeg", "image/png"];
exports.acceptedSoundFileTypes = ["audio/mpeg", "audio/x-wav", "audio/ogg"];
exports.acceptedVideoFileTypes = ["video/webm", "video/mp4"];
exports.accepted3dFileTypes = [
    "model/gltf-binary",
    "model/gltf+json",
    ".glb",
    ".gltf",
    "gltf",
    "glb",
];
exports.BURN_ADDRESS = "0x000000000000000000000000000000000000dEaD";
exports.EXCLUDED_ADDRESSES = [
    exports.BURN_ADDRESS,
    "0x0000000000000000000000000000000000000000",
    "0x0000000000000000000000000000000000000001",
];
exports.IPFS_PREFIX = "ipfs://";
exports.IPFS_GATEWAY_PREFIX = "https://ipfs.io/ipfs/";
exports.docsPortalPrefix = "/docs";
exports.vendorPortalPrefix = "/vendor";
exports.vendorStandalonePrefix = "/standalone";
exports.vendorApiPrefix = "/api/vendor";
exports.customerGalleryPrefix = "/gallery";
exports.customerPassPrefix = "";
exports.customerApiPrefix = "/api/customer";
exports.publicApiPrefix = "/api/public";
exports.jobsUiPrefix = "/jobs";
exports.provenancePagePrefix = "/provenance";
exports.shopifyProductGidPrefix = "gid://shopify/Product/";
exports.shopifyDiscountNodeGidPrefix = "gid://shopify/DiscountCodeNode/";
exports.shopifySubscriptionContractGidPrefix = "gid://shopify/SubscriptionContract/";
exports.shopifyOrderGidPrefix = "gid://shopify/Order/";
exports.shopifyProductVariantGidPrefix = "gid://shopify/ProductVariant/";
exports.shopifyCustomerGidPrefix = "gid://shopify/Customer/";
exports.shopifyShopGidPrefix = "gid://shopify/Shop/";
exports.DEVELOPMENT_LOGGED_OUT_CUSTOMER_ID_SIGNAL = "99999999999999";
exports.myShopifyDomainUrlPropertyName = "shop";
exports.orgIdPropertyName = "orgId";
exports.fromShopifyIframePropertyName = "__from_iframe__";
exports.NOVEL_PASS_USER_EMAIL_PARAM = "userEmail";
exports.NOVEL_SOURCE_TAG_PARAM = "sourceTag";
// prefixing with "AppEnv" to avoid conflicting cookies in different environments
exports.vendorJwtPropertyName = "vendor_".concat(env_1.AppEnv, "_auth_token");
exports.customerJwtPropertyName = "customer_".concat(env_1.AppEnv, "_auth_token");
exports.customerCsrfPropertyName = "customer_".concat(env_1.AppEnv, "_csrf_token");
exports.webPushSubscriptionEndpointPropertyName = "customer_".concat(env_1.AppEnv, "_web_push_sub_endpoint");
exports.iosBrowserInstallInstancePropertyName = "customer_".concat(env_1.AppEnv, "_ios_browser_install_instance");
var SHOPIFY_HEADERS;
(function (SHOPIFY_HEADERS) {
    SHOPIFY_HEADERS["SHOP_DOMAIN"] = "x-shopify-shop-domain";
    SHOPIFY_HEADERS["WEBHOOK_TOPIC"] = "x-shopify-topic";
    SHOPIFY_HEADERS["HMAC_SHA_256"] = "x-shopify-hmac-sha256";
    SHOPIFY_HEADERS["SHOPIFY_SESSION_TOKEN"] = "x-shopify-session-token";
    SHOPIFY_HEADERS["SHOPIFY_EXTENSION_SESSION_TOKEN"] = "x-shopify-extension-session-token";
    SHOPIFY_HEADERS["SHOPIFY_MERCHANT_DOMAIN"] = "x-novel-merchant-domain";
    SHOPIFY_HEADERS["SHOPIFY_API_HEADER"] = "X-Shopify-Access-Token";
})(SHOPIFY_HEADERS || (exports.SHOPIFY_HEADERS = SHOPIFY_HEADERS = {}));
var PUBLIC_HEADERS;
(function (PUBLIC_HEADERS) {
    PUBLIC_HEADERS["INTERNAL_CALL"] = "x-internal-call";
    PUBLIC_HEADERS["NOVEL_NONCE_TARGET_ID"] = "x-novel-nonce-target-id";
})(PUBLIC_HEADERS || (exports.PUBLIC_HEADERS = PUBLIC_HEADERS = {}));
var VENDOR_HEADERS;
(function (VENDOR_HEADERS) {
    VENDOR_HEADERS["AUTHORIZATION"] = "Authorization";
    VENDOR_HEADERS["ORG_DOMAIN"] = "x-org-domain";
    VENDOR_HEADERS["IMPERSONATE"] = "x-impersonate";
})(VENDOR_HEADERS || (exports.VENDOR_HEADERS = VENDOR_HEADERS = {}));
var VENDOR_API_HEADERS;
(function (VENDOR_API_HEADERS) {
    VENDOR_API_HEADERS["NOVEL_API_HEADER"] = "x-novel-access-token";
})(VENDOR_API_HEADERS || (exports.VENDOR_API_HEADERS = VENDOR_API_HEADERS = {}));
var CUSTOMER_HEADERS;
(function (CUSTOMER_HEADERS) {
    CUSTOMER_HEADERS["AUTHORIZATION"] = "Authorization";
    CUSTOMER_HEADERS["CF_IPLATITUDE"] = "x-original-cf-iplatitude";
    CUSTOMER_HEADERS["CF_IPLONGITUDE"] = "x-original-cf-iplongitude";
})(CUSTOMER_HEADERS || (exports.CUSTOMER_HEADERS = CUSTOMER_HEADERS = {}));
var ALCHEMY_HEADERS;
(function (ALCHEMY_HEADERS) {
    ALCHEMY_HEADERS["SIGNATURE"] = "x-alchemy-signature";
    ALCHEMY_HEADERS["TOKEN"] = "x-alchemy-token";
})(ALCHEMY_HEADERS || (exports.ALCHEMY_HEADERS = ALCHEMY_HEADERS = {}));
var ALCHEMY_QUERY_PARAMS;
(function (ALCHEMY_QUERY_PARAMS) {
    ALCHEMY_QUERY_PARAMS["DOMAIN"] = "domain";
    ALCHEMY_QUERY_PARAMS["ADDRESS_INDEX"] = "index";
})(ALCHEMY_QUERY_PARAMS || (exports.ALCHEMY_QUERY_PARAMS = ALCHEMY_QUERY_PARAMS = {}));
var INFLUENCE_IO_HEADERS;
(function (INFLUENCE_IO_HEADERS) {
    INFLUENCE_IO_HEADERS["WEBHOOK_TOPIC"] = "x-webhook-topic";
    INFLUENCE_IO_HEADERS["WEBHOOK_ID"] = "x-webhook-id";
})(INFLUENCE_IO_HEADERS || (exports.INFLUENCE_IO_HEADERS = INFLUENCE_IO_HEADERS = {}));
var apiKeyPropertyName = "apiKey";
function getApiKeyPropertyName(orgDomain) {
    return "".concat(orgDomain, "_").concat(apiKeyPropertyName);
}
exports.getApiKeyPropertyName = getApiKeyPropertyName;
// generator constants
exports.NONE_TRAIT_ID = "sd77GeLz3aQ4FAoHKzu7D";
exports.SINGLE_ATTRIBUTE_LAYER = "AoxARm8KBTbyinQ-e9wBr";
exports.DEFAULT_EMAIL_TEMPLATE_BACKGROUND = "#e5e5e5";
// contract which holds all of the tokens created via opensea
exports.BANNED_CONTRACTS = ["0x495f947276749Ce646f68AC8c248420045cb7b5e"];
exports.ACTIVE_SUBSCRIBER_TAG = "Active Subscriber";
exports.GOOGLE_GENERIC_PASS_CLASS_NAME = "pass";
exports.STRIP_IMAGE_WIDTH = 375;
exports.STRIP_IMAGE_HEIGHT = 144;
exports.STRIP_IMAGE_X2_WIDTH = 750;
exports.STRIP_IMAGE_X2_HEIGHT = 288;
exports.STRIP_IMAGE_X3_WIDTH = 1125;
exports.STRIP_IMAGE_X3_HEIGHT = 432;
exports.APPLE_LOGO_IMAGE_WIDTH = 160;
exports.APPLE_LOGO_IMAGE_HEIGHT = 50;
exports.APPLE_LOGO_IMAGE_X2_WIDTH = 320;
exports.APPLE_LOGO_IMAGE_X2_HEIGHT = 100;
exports.APPLE_LOGO_IMAGE_X3_WIDTH = 480;
exports.APPLE_LOGO_IMAGE_X3_HEIGHT = 150;
exports.GOOGLE_LOGO_IMAGE_WIDTH = 660;
exports.GOOGLE_LOGO_IMAGE_HEIGHT = 660;
exports.GOOGLE_STRIP_IMAGE_WIDTH = 1032;
exports.GOOGLE_STRIP_IMAGE_HEIGHT = 336;
exports.ICON_IMAGE_WIDTH = 500;
exports.ICON_IMAGE_HEIGHT = 500;
exports.DEFAULT_PASS_ICON_IMAGE = "https://novel-commerce.s3.amazonaws.com/Novel_Icon_Dark.jpg";
exports.DEFAULT_PASS_EMAIL_IMAGE = "https://novel-commerce.s3.amazonaws.com/Novel_Pass_Email_Image.jpg";
exports.DEFAULT_PASS_APPLE_LOGO_IMAGE = "https://novel-commerce.s3.amazonaws.com/Novel_Logo_Light.png";
exports.DEFAULT_PASS_GOOGLE_LOGO_IMAGE = "https://novel-commerce.s3.amazonaws.com/Novel_Icon_Dark.jpg";
//image must match exact dimensions for STRIP_IMAGE_X3
exports.DEFAULT_PASS_STRIP_IMAGE = "https://novel-commerce.s3.us-east-1.amazonaws.com/default-novel-banner-apple.png";
exports.DEFAULT_APPLE_OVERLAY = "https://novel-commerce.s3.amazonaws.com/apple-arrow.png";
exports.DEFAULT_APPLE_INSTALL_OVERLAY = "https://novel-commerce.s3.amazonaws.com/apple-add-overlay.png";
//images must match exact dimensions for GOOGLE_STRIP_IMAGE
exports.DEFAULT_PASS_GOOGLE_STRIP_IMAGE = "https://novel-commerce.s3.us-east-1.amazonaws.com/default-novel-banner-google.png";
exports.DEFAULT_GOOGLE_OVERLAY = "https://novel-commerce.s3.us-east-1.amazonaws.com/google-arrow.png";
//google maps
exports.GOOGLE_MAPS_API_KEY = "AIzaSyDXRLr7o-NMHPdSQ7jlvHJlXtdMMor40Vw";
exports.TEXT_VARIABLE_DELIMITER = "@";
exports.PUSH_BODY_TEXT_VARIABLES = [
    "".concat(exports.TEXT_VARIABLE_DELIMITER, "balance"),
    "".concat(exports.TEXT_VARIABLE_DELIMITER, "firstName"),
    "".concat(exports.TEXT_VARIABLE_DELIMITER, "tierName"),
];
exports.REFERRER_TEXT_EDITOR_MASK = "{Referrer}";
exports.SHOP_NAME_EDITOR_MASK = "{shop_name}";
exports.REFERREE_BALANCE_TEXT_EDITOR_MASK = "{Referree Balance}";
exports.DEFAULT_WELCOME_PASS_TEXT = "<h1 style=\"text-align:left;\"><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 40px;font-family: Poppins, sans-serif;\">Say Hello</span></h1>\n<h3 style=\"text-align:left;\"><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 25px;font-family: Poppins, sans-serif;\">to your Wallet Pass!</span></h3>\n<p style=\"text-align:left;\"><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 13px;font-family: Poppins, sans-serif;\">Your {shop_name} Pass awaits you.</span></p>\n<p style=\"text-align:left;\"><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 13px;font-family: Poppins, sans-serif;\">Get early access to products and exclusive rewards!</span></p>";
exports.DEFAULT_WELCOME_REFFERALS_TEXT = "<h1 style=\"text-align:left;\"><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 40px;font-family: Poppins, sans-serif;\">Hello</span></h1>\n<h3 style=\"text-align:left;\"><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 25px;font-family: Poppins, sans-serif;\">You've been referred by </span><span style=\"color: rgb(51,113,206);background-color: rgb(255,255,255);font-size: 22px;font-family: Poppins, sans-serif;\">".concat(exports.REFERRER_TEXT_EDITOR_MASK, "</span></h3>\n<p style=\"text-align:left;\"><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 13px;font-family: Poppins, sans-serif;\">Continue to get your</span> <span style=\"color: rgb(44,130,201);background-color: rgb(255,255,255);font-size: 13px;font-family: Poppins, sans-serif;\"><strong>").concat(exports.REFERREE_BALANCE_TEXT_EDITOR_MASK, "</strong></span><span style=\"color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 13px;font-family: Poppins, sans-serif;\">.</span></p>");
var storefrontScriptStr = "/js/novel-storefront.js";
exports.storefrontProxiedScriptTag = "".concat(appStorefrontConstants_1.appProxyPrefix).concat(storefrontScriptStr);
exports.storefrontUnproxiedScriptTag = "".concat(appDomainConstants_1.appPrefixedHostWithProtocol).concat(exports.vendorPortalPrefix).concat(storefrontScriptStr);
var orderStatusScriptStr = "/js/novel-order-status.js";
exports.orderStatusProxiedScriptTag = "".concat(appStorefrontConstants_1.appProxyPrefix).concat(orderStatusScriptStr);
exports.orderStatusUnproxiedScriptTag = "".concat(appDomainConstants_1.appPrefixedHostWithProtocol).concat(exports.vendorPortalPrefix).concat(orderStatusScriptStr);
