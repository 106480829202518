let alreadyFiredReady = false;
const executionQueue: (() => any)[] = [];

// robust listening for on document ready
export function onReady(callback: () => void): void {
    if (alreadyFiredReady) {
        callback();
    } else {
        executionQueue.push(callback);
    }
}

// listen for ready event
const listeneroptionsObj = { once: true };
if (typeof window !== "undefined") {
    window.addEventListener("DOMContentLoaded", uponReady, listeneroptionsObj);
}

// poll for ready
let readyStateCheckInterval: number | null = null;
let readyStateCheckTimeout: number | null = null;
if (typeof document !== "undefined" && document.readyState === "complete") {
    uponReady();
} else {
    const READY_POLL_INTERVAL = 1000;
    readyStateCheckInterval = setInterval(function () {
        if (typeof document !== "undefined" && document.readyState === "complete") {
            uponReady();
        }
    }, READY_POLL_INTERVAL) as unknown as number;

    // assume ready after 4 seconds
    readyStateCheckTimeout = setTimeout(uponReady, 4000) as unknown as number;
}

function uponReady() {
    if (!alreadyFiredReady) {
        alreadyFiredReady = true; // only fire ready once

        // execute all queued callbacks in order
        while (executionQueue.length) {
            executionQueue.shift()?.();
        }

        // clear all forms of listening
        if (readyStateCheckTimeout) {
            clearTimeout(readyStateCheckTimeout);
        }
        if (typeof window !== "undefined") {
            window.removeEventListener("DOMContentLoaded", uponReady, listeneroptionsObj as any);
        }
        if (readyStateCheckInterval) {
            clearInterval(readyStateCheckInterval);
        }
    }
}
