import { dispatchResolvedHandleData } from "@customer-pass/redux/actionCreators/session";
import { NovelCustomerPassThunkAction } from "@customer-pass/redux/customerPassTypedRedux";
import {
    useNovelCustomerPassDispatch,
    useNovelCustomerPassSelector,
} from "@customer-pass/redux/reduxHooks";
import { asyncDispatch } from "@customer-pass/redux/utils/asyncDispatch";
import { typedFrontendPassApi } from "@customer-pass/utils/typedFrontendPassApi";
import { useAsyncEffect } from "@novel/shared/hooks/useAsyncEffect";
import { ISODateString } from "@novel/shared/interfaces/shared/ISODateString";
import { customerPassPrefix } from "@novel/shared/utils/appConstants";
import { SurfaceableError } from "@novel/shared/utils/SurfaceableError";

export const createAndroidDevice =
    (
        pushSubscription: PushSubscription,
        serviceWorkerRegistration: ServiceWorkerRegistration,
    ): NovelCustomerPassThunkAction<
        | "LOADING_RESOLVED_CUSTOMER"
        | "LOADING_RESOLVED_ORG"
        | "ERROR_LOADING_RESOLVED_ORG"
        | "ERROR_LOADING_RESOLVED_CUSTOMER"
        | "SET_WALLET_PASS_INSTALL_FLOW"
        | "SET_SERVICE_WORKER_REGISTRATION"
        | "LOADED_RESOLVED_ORG"
        | "LOADED_RESOLVED_CUSTOMER"
    > =>
    async (dispatch) =>
        asyncDispatch(
            typedFrontendPassApi.postReq("/api/customer/install/android", {
                reqBody: {
                    deviceOperatingSystem: "android",
                    __deviceId__: pushSubscription.endpoint,
                    __pushToken__: {
                        webPush: {
                            endpoint: pushSubscription.endpoint,
                            // the server side definition doesn't have the "expiresAt" property
                            expiresAt: (pushSubscription as any).expiresAt
                                ? ISODateString.toString(
                                      new Date((pushSubscription as any).expiresAt),
                                  )
                                : undefined,
                            keys: {
                                // taken from: https://gist.github.com/MartijnDwars/c05c531e33e9b39452425b131c482c8f
                                p256dh: btoa(
                                    String.fromCharCode.apply(
                                        null,
                                        Array.from(
                                            new Uint8Array(pushSubscription.getKey("p256dh")!),
                                        ),
                                    ),
                                ),
                                auth: btoa(
                                    String.fromCharCode.apply(
                                        null,
                                        Array.from(
                                            new Uint8Array(pushSubscription.getKey("auth")!),
                                        ),
                                    ),
                                ),
                            },
                        },
                    },
                },
            }),
            () => {
                dispatch({
                    type: "LOADING_RESOLVED_ORG",
                    payload: undefined,
                });

                return dispatch({
                    type: "LOADING_RESOLVED_CUSTOMER",
                    payload: undefined,
                });
            },
            (failure) => {
                dispatch({
                    type: "ERROR_LOADING_RESOLVED_ORG",
                    payload: {
                        user: null,
                        errorMessage: failure.error.message,
                    },
                });

                return dispatch({
                    type: "ERROR_LOADING_RESOLVED_CUSTOMER",
                    payload: {
                        user: null,
                        errorMessage: failure.error.message,
                    },
                });
            },
            (resolvedHandleData) => {
                dispatch({
                    type: "SET_SERVICE_WORKER_REGISTRATION",
                    payload: {
                        serviceWorkerRegistration,
                    },
                });

                return dispatchResolvedHandleData(resolvedHandleData, dispatch);
            },
        );

export function useInitAndroidEffect(): void {
    const dispatch = useNovelCustomerPassDispatch();
    const walletPassInstallFlow = useNovelCustomerPassSelector(
        (state) => state.passUi.walletPassInstallFlow,
    );
    const isChromeBrowser = useNovelCustomerPassSelector((state) => !!state.passUi.isChromeBrowser);
    const passInstallLink = useNovelCustomerPassSelector(
        (state) => state.auth.resolvedCustomer?.passInstallLink,
    );

    return useAsyncEffect(
        async () => {
            if (isChromeBrowser && walletPassInstallFlow === "android-phone") {
                if (
                    typeof window !== "undefined" &&
                    "serviceWorker" in navigator &&
                    "PushManager" in window
                ) {
                    return await new Promise<ServiceWorkerRegistration | undefined>((resolve) => {
                        navigator.serviceWorker.getRegistration().then((registration) => {
                            if (registration) {
                                resolve(registration);
                            } else {
                                navigator.serviceWorker
                                    .register(`${customerPassPrefix}/assets/sw.js`)
                                    .then((registration) => {
                                        resolve(registration);
                                    })
                                    .catch((error) => {
                                        return console.error(
                                            "Service worker registration failed:",
                                            error,
                                        );
                                    });
                            }
                        });
                    });
                } else {
                    console.error("Browser does not support service workers or push messages.");
                }
            }
        },
        async (serviceWorkerRegistration) => {
            if (serviceWorkerRegistration && !passInstallLink) {
                const subscription = await serviceWorkerRegistration.pushManager.getSubscription();
                const notificationRequestState =
                    typeof window !== "undefined" && "PushManager" in window
                        ? Notification.permission
                        : "default";

                // this will actually populate the android pass install link
                // we do not populate it before now as we want to ensure that
                // we map the device to the push notification subscription properly
                // and we can only get the push notification subscritpion client-side
                if (notificationRequestState === "granted" && subscription && !passInstallLink) {
                    const result = await dispatch(
                        createAndroidDevice(subscription, serviceWorkerRegistration),
                    );
                    if (result.type === "ERROR_LOADING_RESOLVED_CUSTOMER") {
                        throw new SurfaceableError(result.payload.errorMessage);
                    }
                } else {
                    dispatch({
                        type: "SET_SERVICE_WORKER_REGISTRATION",
                        payload: {
                            serviceWorkerRegistration,
                        },
                    });
                }
            }
        },
        [isChromeBrowser, walletPassInstallFlow],
    );
}
