import { ITypedStrings } from "@novel/shared/utils/TypedStrings";
import { components } from "@novel/shared/__generated__/GeneratedSharedTypes";

export enum IntegrationVendorEnum {
    KLAVIYO = "klaviyo",
    POSTSCRIPT = "postscript",
    ATTENTIVE = "attentive",
    INFLUENCE = "influence",
    YOTPO = "yotpo",
    SMILE = "smile",
    RIVO = "rivo",
    SUPERFILIATE = "superfiliate",
    INVETERATE = "inveterate",
    LOYALTYLION = "loyaltylion",
    TALKABLE = "talkable",
    STAYAI = "stayai",
}

export const integrationsUsingPoints = [
    IntegrationVendorEnum.INFLUENCE,
    IntegrationVendorEnum.YOTPO,
    IntegrationVendorEnum.SMILE,
    IntegrationVendorEnum.RIVO,
    IntegrationVendorEnum.LOYALTYLION,
];

export const integrationsSubscriptions = [IntegrationVendorEnum.STAYAI];

export const integrationsUsingStoreCredit = [
    IntegrationVendorEnum.SUPERFILIATE,
    IntegrationVendorEnum.INVETERATE,
    IntegrationVendorEnum.TALKABLE,
];

export const loyaltyIntegrations = integrationsUsingPoints.concat(integrationsUsingStoreCredit);

export const integrationsWithDisabledCreditUpdating = [IntegrationVendorEnum.TALKABLE];

export type IOrganizationIntegrationCreate = ITypedStrings<
    components["schemas"]["IOrganizationIntegrationCreate"]
>;

export type IOrganizationIntegrationConfig = ITypedStrings<
    components["schemas"]["IOrganizationIntegrationConfig"]
>;

export type IOrganizationIntegration = ITypedStrings<
    components["schemas"]["IOrganizationIntegration"]
>;
